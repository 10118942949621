import { createContext, useState, ReactNode, Dispatch, SetStateAction } from "react";

// 타입 정의
interface AppContextType {
  fusejs: any;
  setFusejs: Dispatch<SetStateAction<any>>;
}

// 기본값 설정
export const AppContext = createContext<AppContextType>({
  fusejs: null,
  setFusejs: () => {},
});

// Provider 컴포넌트
interface AppProviderProps {
  children: ReactNode;
}

export const AppProvider: React.FC<AppProviderProps> = ({ children }) => {
  const [fusejs, setFusejs] = useState<any>(null);

  return (
    <AppContext.Provider value={{ fusejs, setFusejs }}>
      {children}
    </AppContext.Provider>
  );
};
